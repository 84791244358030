exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-dental-insurance-index-tsx": () => import("./../../../src/pages/dental-insurance/index.tsx" /* webpackChunkName: "component---src-pages-dental-insurance-index-tsx" */),
  "component---src-pages-fixed-indemnity-index-tsx": () => import("./../../../src/pages/fixed-indemnity/index.tsx" /* webpackChunkName: "component---src-pages-fixed-indemnity-index-tsx" */),
  "component---src-pages-health-insurance-index-tsx": () => import("./../../../src/pages/health-insurance/index.tsx" /* webpackChunkName: "component---src-pages-health-insurance-index-tsx" */),
  "component---src-pages-health-insurance-individual-index-tsx": () => import("./../../../src/pages/health-insurance/individual/index.tsx" /* webpackChunkName: "component---src-pages-health-insurance-individual-index-tsx" */),
  "component---src-pages-health-insurance-short-term-index-tsx": () => import("./../../../src/pages/health-insurance/short-term/index.tsx" /* webpackChunkName: "component---src-pages-health-insurance-short-term-index-tsx" */),
  "component---src-pages-how-we-help-index-tsx": () => import("./../../../src/pages/how-we-help/index.tsx" /* webpackChunkName: "component---src-pages-how-we-help-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intelligent-insurance-plans-index-tsx": () => import("./../../../src/pages/intelligent-insurance-plans/index.tsx" /* webpackChunkName: "component---src-pages-intelligent-insurance-plans-index-tsx" */),
  "component---src-pages-legal-health-data-privacy-policy-index-tsx": () => import("./../../../src/pages/legal/health-data-privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-legal-health-data-privacy-policy-index-tsx" */),
  "component---src-pages-life-insurance-final-expense-insurance-index-tsx": () => import("./../../../src/pages/life-insurance/final-expense-insurance/index.tsx" /* webpackChunkName: "component---src-pages-life-insurance-final-expense-insurance-index-tsx" */),
  "component---src-pages-life-insurance-index-tsx": () => import("./../../../src/pages/life-insurance/index.tsx" /* webpackChunkName: "component---src-pages-life-insurance-index-tsx" */),
  "component---src-pages-life-insurance-seniors-index-tsx": () => import("./../../../src/pages/life-insurance/seniors/index.tsx" /* webpackChunkName: "component---src-pages-life-insurance-seniors-index-tsx" */),
  "component---src-pages-medicare-index-tsx": () => import("./../../../src/pages/medicare/index.tsx" /* webpackChunkName: "component---src-pages-medicare-index-tsx" */),
  "component---src-pages-medicare-medicare-advantage-index-tsx": () => import("./../../../src/pages/medicare/medicare-advantage/index.tsx" /* webpackChunkName: "component---src-pages-medicare-medicare-advantage-index-tsx" */),
  "component---src-pages-medicare-medicare-prescription-drug-index-tsx": () => import("./../../../src/pages/medicare/medicare-prescription-drug/index.tsx" /* webpackChunkName: "component---src-pages-medicare-medicare-prescription-drug-index-tsx" */),
  "component---src-pages-medicare-medicare-supplement-index-tsx": () => import("./../../../src/pages/medicare/medicare-supplement/index.tsx" /* webpackChunkName: "component---src-pages-medicare-medicare-supplement-index-tsx" */),
  "component---src-pages-supplemental-insurance-critical-illness-cancer-plans-index-tsx": () => import("./../../../src/pages/supplemental-insurance/critical-illness-cancer-plans/index.tsx" /* webpackChunkName: "component---src-pages-supplemental-insurance-critical-illness-cancer-plans-index-tsx" */),
  "component---src-pages-supplemental-insurance-disability-plans-index-tsx": () => import("./../../../src/pages/supplemental-insurance/disability-plans/index.tsx" /* webpackChunkName: "component---src-pages-supplemental-insurance-disability-plans-index-tsx" */),
  "component---src-pages-supplemental-insurance-hospital-confinement-plans-index-tsx": () => import("./../../../src/pages/supplemental-insurance/hospital-confinement-plans/index.tsx" /* webpackChunkName: "component---src-pages-supplemental-insurance-hospital-confinement-plans-index-tsx" */),
  "component---src-pages-supplemental-insurance-index-tsx": () => import("./../../../src/pages/supplemental-insurance/index.tsx" /* webpackChunkName: "component---src-pages-supplemental-insurance-index-tsx" */),
  "component---src-pages-supplemental-insurance-seniors-index-tsx": () => import("./../../../src/pages/supplemental-insurance/seniors/index.tsx" /* webpackChunkName: "component---src-pages-supplemental-insurance-seniors-index-tsx" */),
  "component---src-pages-supplemental-insurance-wellness-plans-index-tsx": () => import("./../../../src/pages/supplemental-insurance/wellness-plans/index.tsx" /* webpackChunkName: "component---src-pages-supplemental-insurance-wellness-plans-index-tsx" */),
  "component---src-pages-vision-insurance-index-tsx": () => import("./../../../src/pages/vision-insurance/index.tsx" /* webpackChunkName: "component---src-pages-vision-insurance-index-tsx" */),
  "component---src-pages-vision-insurance-seniors-index-tsx": () => import("./../../../src/pages/vision-insurance/seniors/index.tsx" /* webpackChunkName: "component---src-pages-vision-insurance-seniors-index-tsx" */),
  "component---src-pages-wp-landing-page-slug-tsx": () => import("./../../../src/pages/{wpLandingPage.slug}.tsx" /* webpackChunkName: "component---src-pages-wp-landing-page-slug-tsx" */)
}

